
:root{
    --navbar-background: rgb(255, 255, 255);
    --background: rgb(255, 255, 255);
    --text-colour: #312f2f;
    --text-intensed: #000000;
    --overlay-color:rgba(50, 146, 166, 0.53);
    --footer-background: #bdfced

}

*, ::after, ::before {
    box-sizing: border-box
}

html {
    font-family: Arial;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

main, nav, section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex=\"-1\"]:focus:not(:focus-visible) {
    outline: 0 !important
}

h1, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ul ul {
    margin-bottom: 0
}

b {
    font-weight: bolder
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button, input, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

[type=button], button {
    -webkit-appearance: button
}

[type=button]:not(:disabled), button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    overflow: auto;
    resize: vertical
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.h1, .h3, .h4, .h5, h1, h3, h4, h5 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1, h1 {
    font-size: 2.5rem
}

.h3, h3 {
    font-size: 1.75rem
}

.h4, h4 {
    font-size: 1.5rem
}

.h5, h5 {
    font-size: 1.25rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-8, .col-md-12, .col-sm-5, .col-sm-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 576px) {
    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .offset-lg-1 {
        margin-left: 8.333333%
    }

    .offset-lg-2 {
        margin-left: 16.666667%
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark:focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block + .btn-block {
    margin-top: .5rem
}

input[type=button].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus, .nav-link:hover {
    text-decoration: none
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar .container, .navbar .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

.navbar-toggler-icon {
    color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer > * {
    margin: .25rem
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: var(--footer-background) !important
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-light {
    background-color: #f8f8f8 !important
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
    background-color: #1d2124 !important
}

.border-0 {
    border: 0 !important
}

.d-none {
    display: none !important
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important
    }
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.m-0 {
    margin: 0 !important
}

.my-0 {
    margin-top: 0 !important
}

.my-0 {
    margin-bottom: 0 !important
}

.mx-2 {
    margin-right: .5rem !important
}

.ml-2, .mx-2 {
    margin-left: .5rem !important
}

.mr-3 {
    margin-right: 1rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mt-5, .my-5 {
    margin-top: 3rem !important
}

.my-5 {
    margin-bottom: 3rem !important
}

.ml-5 {
    margin-left: 3rem !important
}

.pl-0 {
    padding-left: 0 !important
}

.py-1 {
    padding-top: .25rem !important
}

.py-1 {
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important
}

.py-2 {
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important
}

.py-3 {
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important
}

.px-4 {
    padding-right: 1.5rem !important
}

.py-4 {
    padding-bottom: 1.5rem !important
}

.px-4 {
    padding-left: 1.5rem !important
}

.pt-5, .py-5 {
    padding-top: 3rem !important
}

.py-5 {
    padding-bottom: 3rem !important
}

.ml-auto {
    margin-left: auto !important
}

.text-center {
    text-align: center !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-white {
    color: #fff !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:focus, a.text-warning:hover {
    color: #ba8b00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:focus, a.text-danger:hover {
    color: #a71d2a !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus, a.text-dark:hover {
    color: #121416 !important
}

.text-muted {
    color: #6c757d !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    img {
        page-break-inside: avoid
    }

    h3, p {
        orphans: 3;
        widows: 3
    }

    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }
}

/*# sourceMappingURL=bootstrap.min.css.map */
* {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

main, section {
    display: block
}

body {
    margin: 0;
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color:var(--background)
}

h3 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

a {
    color: #0ea0ff;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0075c1;
    text-decoration: underline
}

a:not([href]), a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

img {
    border-style: none
}

img {
    vertical-align: middle
}

.h3, h3 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h3, h3 {
    font-size: 1.75rem
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col-lg-3, .col-lg-4, .col-lg-5, .col-md-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }
}

@keyframes a {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes b {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes c {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

@media print {
    * {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    img {
        page-break-inside: avoid
    }

    h3, p {
        orphans: 3;
        widows: 3
    }

    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container, body {
        min-width: 992px !important
    }
}

.portfolio-block {
    padding-bottom: 60px;
    padding-top: 60px;
    border-bottom: 2px solid var(--text-intensed);
}

.portfolio-block.website h3 {
    font-weight: 700
}

.portfolio-block.website p {
    opacity: .9
}

.portfolio-laptop-mockup {
    margin: auto;
    margin-top: 30px;
    max-width: 280px
}

.portfolio-block.mobile-app .text, .portfolio-block.website .text {
    text-align: center
}

.portfolio-laptop-mockup .screen {
    border: 1px solid #9c9c9c;
    border-bottom: none;
    width: 250px;
    height: 160px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    left: 15px
}

.portfolio-laptop-mockup .screen .screen-content {
    border: 1px solid #c5c5c5;
    background-position: 50%;
    background-size: cover;
    height: 100%
}

.portfolio-laptop-mockup .keyboard {
    width: 280px;
    height: 10px;
    border: 1px solid #9c9c9c;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #fff
}

.portfolio-block.mobile-app {
    padding-top: 80px;
    padding-bottom: 80px
}

.portfolio-block.mobile-app h3 {
    font-weight: 700
}

.portfolio-block.mobile-app p {
    opacity: .9
}

.portfolio-phone-mockup {
    border: 1px solid #9c9c9c;
    width: 150px;
    height: 300px;
    padding: 15px 7px 0;
    border-radius: 15px;
    background-color: #fff;
    margin: auto;
    margin-bottom: 20px
}

.portfolio-phone-mockup .phone-screen {
    height: 240px;
    border: 1px solid #9c9c9c;
    margin-bottom: 7px;
    background-size: cover
}

.portfolio-phone-mockup .home-button {
    width: 28px;
    height: 28px;
    background: #fdfeff;
    border: 1px solid #ccc;
    border-radius: 30px;
    margin: auto
}

@media (min-width: 768px) {
    .portfolio-block {
        padding-bottom: 100px;
        padding-top: 100px
    }

    .portfolio-laptop-mockup {
        max-width: 350px
    }

    .portfolio-laptop-mockup .screen {
        width: 410px;
        height: 210px
    }

    .portfolio-laptop-mockup .keyboard {
        width: 450px
    }
}

@media (min-width: 992px) {
    .portfolio-laptop-mockup {
        margin-top: 0
    }

    .portfolio-block.mobile-app .text, .portfolio-block.website .text {
        text-align: right
    }
}
body {
    margin-top: 105px; }

.navbar {
    width: 100%;
    box-shadow: 2px 2px 5px #3292a6;
    opacity: 0.9;
    background: var(--navbar-background); }
.navbar .nav-item {
    font-size: 1.4rem;
    padding-right: 1.4rem; }

#home {
    position: relative;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='1' viewBox='0 0 40 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h20v1H0z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    min-height: 600px; }
#home .primary-overlay {
    background: var(--overlay-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }


#about-me img {
    margin-top: -50px; }
#about-me .card:hover {
    background: rgba(83, 93, 97, 0.33);
    color: #100d0d; }

.example {
    -ms-overflow-style: none;
}
::-webkit-scrollbar{
    width: 10px;

}
::-webkit-scrollbar-track{
    border:7px solid #232943;
    box-shadow: inset 0 0 2.5px 2px rgba(0,0,0,0.5);

}
::-webkit-scrollbar-thumb{
    background: linear-gradient(45deg,#06dee1,#79ff6c);
    border-radius: 3px;
}

@media (max-width: 768px) {
    #showcase {
        min-height: 500px; }
    #showcase h1 {
        font-size: 4rem; }

    .mb-resp {
        margin-bottom: 1rem; } }

#projects{
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%230f0d13' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.text-lighter{
    color: var(--text-colour)
}
.text-bolder{
    color: var(--text-intensed);
}
.background{
    background-color: var(--background) !important;
    margin: 0;
}
.project-font-size{
    font-size: 20px;
}
.about-me-font-size{
    font-size: 20px;
}
.welcome-page-font-size{
    font-size: 1.75rem;
}
.mt-3{
    margin-top: 3px;
}
.info-msg{
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    color: #059;
    background-color: #BEF;
}

@media (max-width: 768px) {
    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
        align-content: center;

    }

    .navbar .navbar-collapse {
        text-align: justify;
        float: left;
        float: right;

    }
}